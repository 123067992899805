<template>
  <section id="super">
      <section id="hero">
        <v-parallax dark :src='require("@/assets/images/background.png")' height="1080">
          <v-row justify="center" class="main-text">
            <v-col cols="10">
              <v-row align="center" justify="center">
                <v-col cols="12" md="6" xl="8">
                  <h1 class="display-2 font-weight-bold mb-4">SPEECH TO TEXT</h1>
                  <h1 class="font-weight-light">
                    Сервис, который поможет вам <br />
                    перевести ваши аудио записи в текст!
                  </h1>
                  <span v-if="!isLoggedIn">
                    <v-btn
                        rounded
                        color="primary"
                        x-large
                        to="/signup"
                        class="mt-5"
                    >
                    Начать
                    <v-icon class="ml-2">mdi-microphone</v-icon>
                  </v-btn>
                  </span>
                </v-col>
                <v-col cols="12" md="6" xl="4" class="hidden-sm-and-down"> </v-col>
              </v-row>
            </v-col>
          </v-row>
          <div class="svg-border-waves text-white">
            <v-img :src='require("@/assets/images/borderWaves.svg")' />
          </div>
        </v-parallax>
      </section>
      <v-container fluid id="about-me" class="white">
        <v-row >
          <v-col cols="12" md="6" xl="8" class="mt-16 mb-16" justify="center">
            <v-container class="text-center">
              <h2 class="display-2 font-weight-bold mb-3">О СЕРВИСЕ</h2>
              <v-responsive class="mx-auto mb-8" width="56">
                <v-divider class="mb-1"></v-divider>
                <v-divider></v-divider>
              </v-responsive>
              <v-responsive class="mx-auto title font-weight-light mb-8" max-width="720" >
                Иногда Вам нужно перевести ваши аудио записи в текст. К примеру, сделать расшифровку вашей встречи в зум или перевести записанное интервью с диктофона в текст.  Наш сервис поможет сделать это быстро, легко и дешево. Загрузите свою аудио запись в вашем личном кабинете, и вы не успеет выпить кружку кофе как сможете скачать готовую расшифровку.
              </v-responsive>
            </v-container>
          </v-col>
          <v-col cols="6" md="6" xl="4" class="hidden-sm-and-down">
            <v-img :src='require("@/assets/images/about.png")' contain max-height="700" />
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid id="features" class="grey lighten-4" >
        <v-row align="center" justify="space-around" >
        <v-col cols="12" class="text-center mt-10" >
          <h2 class="display-2 font-weight-bold mb-3">ОСОБЕННОСТИ</h2>
          <v-responsive class="mx-auto mb-8" width="56">
            <v-divider class="mb-1"></v-divider>
            <v-divider></v-divider>
          </v-responsive>
        </v-col>
        <v-col
            cols="12"
            sm="4"
            class="text-center"
            v-for="(feature, i) in features"
            :key="i"
        >
          <v-hover v-slot:default="{ hover }">
            <v-card
                class="card"
                shaped
                min-height="400"
                :elevation="hover ? 10 : 4"
                :class="{ up: hover }"
            >
              <v-theme-provider dark>
                <div>
                  <v-avatar
                      color="primary"
                      size="88"
                      class="mt-4 mb-4"
                  >
                    <v-icon
                        large
                        v-text="feature.icon"
                    ></v-icon>
                  </v-avatar>
                </div>
              </v-theme-provider>
              <v-card-title
                  class="justify-center font-weight-black text-uppercase"
                  v-text="feature.title"
              ></v-card-title>

              <v-card-text
                  class="subtitle-1"
                  v-text="feature.text"
              >
              </v-card-text>
            </v-card>
          </v-hover>
        </v-col>
          <v-col cols="12" class="text-center mb-10" >
          </v-col>
      </v-row>
      </v-container>
    </section>
</template>
<script>
import axios from "axios";

export default {
  data () {
    return {
      features: [
        {
          icon: 'mdi-currency-rub',
          title: 'Почти бесплатно',
          text: 'В отличии от других сервисов который просят с вас за расшифровку от 10 долларов за час распознавания. Наши тарифы очень скромные расшифровка часовой записи обойдется всего в 100 рублей. При регистрации вы получите 10 минут на расшифровку бесплатно.',
        },
        {
          icon: 'mdi-update',
          title: 'Частые обновления',
          text: 'На данный момент наш сервис поддерживает только русский язык для распознавания, но в ближайшее время мы добавим и другие языки. Мы работает над Telegram ботом, который поможет вам расшифровывать ваши записи прямо через мессенджер.',
        },
        {
          icon: 'mdi-shield-outline',
          title: 'Поддержка',
          text: 'Если у вас возникли проблемы во время работы с сервисом или есть пожелания по улучшению работы сервиса просто дайте нам знать, написав об этом нам на почту support@speechetotext.ru.',
        },
      ],
    }
  },
  computed: {
    isLoggedIn: function() {
      return this.$store.getters.isLoggedIn;
    },
  },
  created() {
    axios.get(process.env.VUE_APP_API_URL + "/api/v1/profile/info")
        .then()
        .catch((err) => {
          if (err.response.status === 401) {
            this.$store.dispatch('logout');
          }
    });
  },
};
</script>

<style lang="scss">
.circle {
  stroke: white;
  stroke-dasharray: 650;
  stroke-dashoffset: 650;
  -webkit-transition: all 0.5s ease-in-out;
  opacity: 0.3;
}

.playBut {
  /*  border: 1px solid red;*/
  display: inline-block;
  -webkit-transition: all 0.5s ease;

  .triangle {
    -webkit-transition: all 0.7s ease-in-out;
    stroke-dasharray: 240;
    stroke-dashoffset: 480;
    stroke: white;
    transform: translateY(0);
  }

  &:hover {
    .triangle {
      stroke-dashoffset: 0;
      opacity: 1;
      stroke: white;
      animation: nudge 0.7s ease-in-out;

      @keyframes nudge {
        0% {
          transform: translateX(0);
        }
        30% {
          transform: translateX(-5px);
        }
        50% {
          transform: translateX(5px);
        }
        70% {
          transform: translateX(-2px);
        }
        100% {
          transform: translateX(0);
        }
      }
    }

    .circle {
      stroke-dashoffset: 0;
      opacity: 1;
    }
  }
}
</style>

<style>
.main-text {
  padding-top: 350px;
}

.btn-play {
  transition: 0.2s;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

#hero {
  z-index: 0;
}
.svg-border-waves img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: -2px;
  z-index: -1;
}

.card {
  min-height: 300px;
  padding: 10px;
  transition: 0.5s ease-out;
}

.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}

.card h1 {
  margin-bottom: 10px;
}

.zoom-efect {
  transform: scale(1.1);
}

.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}
section {
  position: relative;
}

</style>
